<template>
  <rcc-confirm-dialog
    :is-show.sync="isShow"
    v-bind="dialogData"
    @cancel="() => { isShow = false }"
  />
</template>

<script>
import RccConfirmDialog from 'Components/dialogs/rcc-confirm-dialog'

export default {
  name: 'RccLoadingOverlay',

  components: {
    RccConfirmDialog
  },

  data() {
    return {
      isShow: false,
      dialogData: {}
    }
  },

  watch: {
    isShow(value) {
      if (!value) {
        this.dialogData = {}
      }
    }
  },

  created() {
    window.events.$on('confirm', this.showConfirmDialog)
  },

  methods: {
    showConfirmDialog(data) {
      this.dialogData = data
      this.isShow = true
    }
  }
}
</script>
