<template>
  <v-navigation-drawer
    :class="classes"
    :value="isOpen"
    app
    flat
  >
    <div class="sidebar__logo">
      {{ logoText }}
    </div>

    <div>
        <div
          v-for="(group, index) in menu"
          :key="index"
        >
          <v-subheader
            v-if="group.items && group.items.length > 0"
            class="subheader"
          >
            {{ group.group }}
          </v-subheader>

          <v-list-item
            v-for="(item, index) in group.items"
            :key="index"
            :class="getItemClasses(item)"
            :disabled="item.isDisabled"
            @click="handleMenuItemClick(item.pageName)"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </div>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'rcc-sidebar',

  data() {
    return {
      menuItems: []
    }
  },

  computed: {
    ...mapState(['isTableEditingEnabled']),
    ...mapGetters(['role']),

    menu() {
      return this.menuItems
        .map(group => ({
          ...group,
          items: group.items.filter(item => this.hasAccess(item.pageName) || item.isDisabled)
        }))
    },

    classes() {
      return [
        'rcc-sidebar',
        this.isTableEditingEnabled && 'rcc-sidebar_is-no-pointers-events'
      ]
    },

    logoText() {
      return this.isSupplierRole ? 'MyRCC 2.0' : 'SHIVA2-new'
    },

    isSupplierRole() {
      return this.role === this.$constants.users.supplier
    }
  },

  props: {
    isOpen: {
      type: Boolean,
      default: true
    }
  },

  watch: {
    $route(value) {
      let menuItems = []

      this.menu.forEach(item => {
        menuItems = menuItems.concat(item.items)
      })
    }
  },

  created() {
    this.getMenuItems()
  },

  methods: {
    handleMenuItemClick(pageName) {
      if (this.$route.name !== pageName) {
        this.$router.push({ path: '/', name: pageName })
      }
    },

    hasAccess(name) {
      const role = this.role
      const route = this.$router.options.routes.find(item => item.name === name)

      return route && (
        (
          !route.meta.access ||
          route.meta.access.includes(role)
        ) && (
          route.meta.additionalAccessRules === undefined ||
          route.meta.additionalAccessRules()
        )
      )
    },

    getItemClasses(item) {
      return [
        'list-item',
        item.pageName === this.$route.meta.sidebarItem && 'list-item_is-active'
      ]
    },

    async getMenuItems() {
      this.menuItems = (this.isSupplierRole ?
        await import('./sidebar-items-supplier.js') :
        await import('./sidebar-items-common.js')).default
    }
  }
}
</script>

<style lang="scss" scoped>
.rcc-sidebar.v-navigation-drawer {
  &_is-no-pointers-events {
    pointer-events: none;
  }

  &--fixed {
    z-index: 100;
  }
}
.sidebar__logo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;

  font-size: 30px;
  font-weight: bold;
  color: $primary;

  &::before {
    content: '';
    animation: highlight 6s linear infinite;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 2vw;
    background-color: #ffffffb4;
    transform: translateX(-4vw) skewX(-45deg);
  }

  &:after {
    position: absolute;
    content: '';
    width: 120px;
    height: 60px;
    left: 30px;
    background: $main-background;
    z-index: -1;
    border-radius: 50px;
    animation: backgroundMove 3s ease infinite
  }
}

.sidebar__items-group {
  display: flex;
  justify-content: flex-start;
}

.list-item {
  &::before {
    background: $main-background;
    left: 100%;
  }

  &:hover {
    &:before {
      opacity: 1;
      left: 0;
    }
  }

  &_is-active {
    color: $primary;
    background: $main-background;

    .v-list-item__title {
      color: $primary;
    }
  }

  ::v-deep .v-ripple__container {
    color: $primary;
  }

  .v-list-item__content {
    padding: 0;
  }

  .v-list-item__icon {
    margin: 6px 15px 0 0;
  }
}

@keyframes highlight {
  from {
    transform: translateX(-4vw) skewX(-45deg);
  }

  90% {
    transform: translateX(-4vw) skewX(-45deg);
  }

  to {
    transform: translateX(37vw) skewX(-45deg);
  }
}

@keyframes backgroundMove {
  from {
    transform: translateY(0);
  }

  30% {
    transform: translateY(-3px);
  }

  30% {
    transform: translateX(-10px);
  }

  70% {
    transform: translateY(3px);
  }

  30% {
    transform: translateX(10px);
  }

  to {
    transform: translateY(0);
  }
}
</style>

<style lang="scss">
.rcc-sidebar {
  .list-item {
    min-height: 35px;
  }
}
</style>
