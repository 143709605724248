import Vue from 'vue'
import Vuex from 'vuex'
import constants from '@/utils/constants'
import { parseJwt } from '@/utils/strings'

Vue.use(Vuex)

const ACCESS_TOKEN = 'rcc'
const REFRESH_TOKEN = 're-rcc'

const COLOR_CLASSES = {
  'success': '_success',
  'warning': '_warning',
  'error': '_error'
}

const ALIGN_CLASSES = {
  'center': '_center'
}

export default new Vuex.Store({
  state: {
    axios: null,
    apiMethods: null,
    user: {
      access_token: null,
      _refresh: null,

      _id: null,
      _login: null,
      _fio: null,
      _role: null,
      _supplierData: null
    },
    breadcrumbs: [],
    staticTop: 0,
    refundChanges: 0,
    notifications: [],
    returnsFilters: [],
    returnsCurrentPage: 0,
    isTableEditingEnabled: false,
    isReturnsManagementModeEnabled: false,

    modifyCellFunctions: {
      width: (_this, modifier) => { _this.$refs.cellRef.style.width = modifier.value },
      'max-width': (_this, modifier) => { _this.$refs.cellRef.style.maxWidth = modifier.value },
      'min-width': (_this, modifier) => { _this.$refs.cellRef.style.minWidth = modifier.value },
      edit: _this => { _this.isEditAction = true },
      tooltip: (_this, modifier) => {
        _this.isTooltip = true
        _this.tooltipCustomValue = modifier.value || ''
      },
      icon: (_this, modifier) => {
        if (modifier.isRequired && !_this.realValue) {
          return
        }

        _this.icon = modifier.value
      },
      color: (_this, modifier) => { _this.cellClasses.push('rcc-cell' + COLOR_CLASSES[modifier.value]) },
      isWarning: (_this, modifier) => {
        if (modifier.value) {
          _this.cellClasses.push('rcc-cell_is-warning')
        }
      },
      align: (_this, modifier) => { _this.cellClasses.push('rcc-cell' + ALIGN_CLASSES[modifier.value]) },
      'pre-wrap': _this => {
        _this.$refs.cellRef.querySelector('.rcc-cell__content').classList.add('rcc-cell__content_pre-wrap')
      },
      stringTruncation: _this => {
        _this.$refs.cellRef.querySelector('.rcc-cell__content').classList.add('rcc-cell__content_string-truncation')
      },
      filler: (_this, modifier) => { _this.filler = modifier.value }
    }
  },
  mutations: {
    switchReturnsManagementModeEnabled (state, payload) { state.isReturnsManagementModeEnabled = payload },
    setRefundChanges (state, payload) { state.refundChanges = payload },
    setNotifications (state, payload) { state.notifications = payload },
    setReturnsFilters (state, payload) { state.returnsFilters = payload },
    setReturnsCurrentPage (state, payload) { state.returnsCurrentPage = payload },
    setIsTableEditingEnabled (state, payload) { state.isTableEditingEnabled = payload },
    setBreadcrumbs (state, payload) { state.breadcrumbs = payload },
    setStaticTop (state, payload) { state.staticTop = payload }
  },
  actions: {
    async init ({ state, dispatch }) {
      const { user } = state

      if (!user.access_token) {
        user._refresh = localStorage.getItem(REFRESH_TOKEN)
        user.access_token = localStorage.getItem(ACCESS_TOKEN)
        dispatch('setAxiosHeader')
      }

      if (user.access_token) {
        const data = parseJwt(user.access_token)
        user._id = data.id
        user._login = data.login
        user._fio = data.fio
        user._role = data.role
        dispatch('getSupplierData')
      }
    },

    setTokens ({ state, dispatch }, data) {
      state.user.access_token = data.access
      localStorage.setItem(ACCESS_TOKEN, data.access)
      state.user._refresh = data.refresh
      localStorage.setItem(REFRESH_TOKEN, data.refresh)
      dispatch('setAxiosHeader')
      dispatch('init')
    },

    setAxiosHeader ({ state }) {
      state.axios.defaults.headers.common['Authorization'] = 'Bearer ' + state.user.access_token
    },

    async getSupplierData({ state }) {
      const { user } = state

      if (user._role === constants.users.supplier) {
        try {
          const data = await state.apiMethods.supplierProfile.get()
          user._supplierData = data
        } catch (error) {
          console.error(error)
        }
      }
    },

    logout () {
      localStorage.removeItem(ACCESS_TOKEN)
      localStorage.removeItem(REFRESH_TOKEN)
      window.location = '/login'
    },

    refresh ({ state, dispatch }) {
      const { user } = state

      return new Promise((resolve, reject) => {
        state.axios.post('/admin/refresh_token', { refresh: user._refresh })
          .then(response => {
            dispatch('setTokens', response.data)
            resolve(user.access_token)
          })
          .catch(err => {
            resolve(err)
          })
      })
    }
  },
  getters: {
    id: state => state.user._id,
    login: state => state.user._login,
    fio: state => state.user._fio,
    role: state => state.user._role,
    supplierData: state => state.user._supplierData
  }
})
