<template>
  <div class="layout-no-menu layout-wrapper">
    <slot />
    <rcc-snackbar />
  </div>
</template>

<script>
import RccSnackbar from '@/components/snackbar/rcc-snackbar'

export default {
  name: 'LayoutNoMenu',

  components: {
    RccSnackbar
  }
}
</script>
