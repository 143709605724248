export default api => {
  return {
    get: () => {
      return api.get('/supplier/profile')
        .then(({ data }) => data)
    },

    update: params => {
      return api.post('/supplier/profile', params)
        .then(({ data }) => {
          return data
        })
    }
  }
}
