export function getReturnPointsList(params) {
  return this.$api.get('admin/return-point/list', params)
    .then(({ data }) => {
      return data
    })
    .catch(error => Promise.reject(error))
}

export default {
  methods: {
    getReturnPoints(params) {
      return this.$api.get('admin/return-points', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    getReturnPoint(id) {
      return this.$api.get('admin/return-point/' + id)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    createReturnPoint(params) {
      return this.$api.post('admin/return-point/0', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    updateReturnPoint(id, params) {
      return this.$api.post('admin/return-point/' + id, params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    }
  }
}

export function returnPoints(api) {
  return {
    dcList: params => {
      return api.get('admin/return-point/list', params)
        .then(({ data: { items } }) => {
          return items
        })
    },
    list: params => {
      return api.get('admin/return-points', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    block: id => {
      return api.post(`admin/return-point/${id}/block`)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    }
  }
}
