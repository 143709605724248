export default api => {
  return {
    list: () => {
      return api.get('admin/district')
        .then(({ data: { results } }) => {
          return results
        })
    },

    create: payload => {
      return api.post('admin/district', payload)
    },

    get: id => {
      return api.get(`admin/district/${id}`)
        .then(({ data }) => {
          return data
        })
    },

    update: (id, payload) => {
      return api.put(`admin/district/${id}`, payload)
    },

    delete: id => {
      return api.delete(`admin/district/${id}`)
    }
  }
}
