export default (api, store) => {
  return {
    get: () => {
      return api.get('admin/profile')
        .then(({ data }) => data)
    },

    update: params => {
      return api.post('admin/profile', params)
        .then(({ data }) => {
          return data
        })
    }
  }
}
