export function getNetworksList(params) {
  return this.$api.get('admin/network/list', params)
    .then(({ data }) => {
      return data
    })
    .catch(error => Promise.reject(error))
}

export default {
  methods: {
    getTradingNetworks(params) {
      return this.$api.get('admin/networks', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    createTradingNetwork(params) {
      return this.$api.post('admin/network/0', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    getTradingNetwork(id) {
      return this.$api.get('admin/network/' + id)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    updateTradingNetwork(id, params) {
      return this.$api.post('admin/network/' + id, params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    }
  }
}

export function tradingNetworks(api) {
  return {
    allList: (id, params) => {
      return api.get('admin/network/list')
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    }
  }
}
