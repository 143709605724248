export default api => {
  return {
    allList: params => {
      return api.get('admin/store/list', params)
        .then(({ data }) => data)
    },

    list: params => {
      return api.get('admin/stores', params)
        .then(({ data }) => data)
    },

    get: id => {
      return api.get(`admin/store/${id}`)
        .then(({ data }) => data)
    },

    create: params => {
      return api.post('admin/store/0', params)
        .then(({ data }) => data)
    },

    update: (id, params) => {
      return api.post(`admin/store/${id}`, params)
        .then(({ data }) => data)
    }
  }
}
