export function getReturnStatusesList(params) {
  return this.$api.get('admin/return_status/list', params)
    .then(({ data }) => {
      return data
    })
    .catch(error => Promise.reject(error))
}

export default {
  methods: {
    getReturnStatuses(params) {
      return this.$api.get('admin/return_statuses', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    getReturnStatus(id) {
      return this.$api.get('admin/return_status/' + id)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    createReturnStatus(params) {
      return this.$api.post('admin/return_status/0', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    updateReturnStatus(id, params) {
      return this.$api.post('admin/return_status/' + id, params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    }
  }
}

export function returnStatuses(api) {
  return {
    allList: (params = {}) => {
      return api.get('admin/return_status/list', params)
        .then(({ data: { items } }) => {
          return items
        })
        .catch(error => Promise.reject(error))
    }
  }
}
