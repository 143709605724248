<template>
  <v-app-bar app flat color="white" class="rcc-header">
    <v-app-bar-nav-icon @click="$emit('clickToDrawerIcon')"></v-app-bar-nav-icon>

    <div class="rcc-header__info-block">
      <v-breadcrumbs :items="breadcrumbs">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>

      <rcc-refund-changes />
    </div>

    <v-spacer />

    <v-menu
      v-if="!isSupplierRole"
      v-model="isShowNotifications"
      transition="scroll-y-transition"
      :close-on-content-click="false"
      class="rcc-header__notifications-menu"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <div
            class="notifications"
            :class="{'has-notifications': filteredNotifications.length > 0}"
          >
            <v-icon>mdi-bell</v-icon>
          </div>
        </v-btn>
      </template>

      <div class="rcc-header__notifications" v-if="filteredNotifications.length > 0">
        <button
          class="rcc-header__close-all-notifications"
          @click="handleCloseAllNotificationsClick"
        >
          <v-icon small>mdi-bell-remove-outline</v-icon>
          <span>Закрыть все</span>
        </button>

        <v-list class="rcc-header__notifications-list">
          <v-list-item-group class="rcc-header__list-group">
            <rcc-notification
              class="rcc-header__notification-item"
              v-for="(item, index) in notifications"
              :key="index"
              :message="item.message"
              @close="removeNotification(item.id)"
            />
          </v-list-item-group>
        </v-list>
      </div>

      <div v-else class="rcc-header__no-notifications">
        <v-icon small>mdi-bell-off-outline</v-icon>
        <span>Нет новых уведомлений</span>
      </div>

    </v-menu>

    <v-btn
      icon
      @click="$router.push({path: '/', name: 'user-profile'})"
    >
      <v-icon>mdi-account</v-icon>
    </v-btn>

    <rcc-dialog-button
      icon="mdi-logout"
      title="Точно хотите выйти?"
      confirm-text="Да"
      @confirm="handleLogOutDialogConfirm"
    />
  </v-app-bar>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import RccRefundChanges from 'Components/refund-changes'
import RccDialogButton from 'Components/dialog-button/rcc-dialog-button.vue'
import RccNotification from './notification.vue'
import storage from '@/utils/storage'

const MAX_NOTIFICATION_COUNT = 50

export default {
  name: 'RccHeader',

  components: {
    RccRefundChanges,
    RccDialogButton,
    RccNotification
  },

  data() {
    return {
      isShowNotifications: false,
      closedMessages: []
    }
  },

  computed: {
    ...mapState(['breadcrumbs', 'notifications']),
    ...mapGetters(['role']),

    filteredNotifications() {
      return this.notifications
        .filter(item => !this.closedMessages
          .includes(item.id)).slice(0, MAX_NOTIFICATION_COUNT + 1)
    },

    isSupplierRole() {
      return this.role === this.$constants.users.supplier
    }
  },

  mounted() {
    this.closedMessages = storage.getCookie('closedNotifications') || []
  },

  methods: {
    handleLogOutDialogConfirm() {
      return
    },

    removeNotification(notificationId) {
      this.closedMessages.push(notificationId)
      storage.setCookie('closedNotifications', this.closedMessages, { expires: 1 } )
    },

    handleCloseAllNotificationsClick() {
      this.notifications.forEach(item => {
        this.closedMessages.push(item.id)
      })

      storage.setCookie('closedNotifications', this.closedMessages, { expires: 1 } )
    }
  }
}
</script>

<style lang="scss" scoped>
.rcc-header {
  &__info-block {
    display: flex;
    align-items: baseline;
  }

  .has-notifications {
    &:after {
      position: absolute;
      border-radius: 50%;
      right: 0px;
      top: 3px;
      content: '';
      width: 12px;
      height: 12px;
      background: $error;
      border: solid 2px white;
    }
  }

  &__notification-item {
    margin: 10px 0;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.26);
    &:last-child {
      margin-top: 0;
    }
  }

  &__notifications {
    background: white;
    position: relative;
    max-width: 300px;
    padding: 1px 5px;
    padding-bottom: 40px;
  }

  &__notifications-list {
    max-height: 500px;
    overflow: hidden;
    overflow-y: auto;
    border-bottom: 1px solid rgba(0, 0, 0, 0.26);
  }

  &__close-all-notifications {
    position: absolute;
    bottom: 11px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    gap: 5px;
    transition: 0.3s ease;

    &:hover {
      color: $error;

      i {
        color: $error;
      }
    }
  }

  &__no-notifications {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    background: white;
  }
}
</style>
