import { returns } from '@/api/returns'
import { suppliers } from '@/api/suppliers'
import notifications from '@/api/notifications'
import { returnStatuses } from '@/api/return-statuses'
import { tradingNetworks } from '@/api/trading-networks'
import { returnPoints } from '@/api/return-points'
import { regions } from '@/api/regions'
import x5 from '@/api/x5'
import districts from '@/api/districts'
import stores from '@/api/stores'
import userProfile from '@/api/user-profile'
import supplierProfile from '@/api/supplier-profile'
import logs from '@/api/logs'
import { managers } from '@/api/users'

export default function(api, store) {
  return {
    returns: returns(api),
    suppliers: suppliers(api),
    notifications: notifications(api),
    returnStatuses: returnStatuses(api),
    tradingNetworks: tradingNetworks(api),
    returnPoints: returnPoints(api),
    regions: regions(api),
    x5: x5(api),
    districts: districts(api),
    stores: stores(api),
    userProfile: userProfile(api, store),
    supplierProfile: supplierProfile(api),
    logs: logs(api),
    managers: managers(api)
  }
}
