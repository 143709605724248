const stash = []

const rpc = {}
const events = {
  onopen: {},
  onclose: {},
  onmessage: {},
  rpc_error: {},
  rpc_result: {}
}

let ws, wsTimer, wsUrl, wsOpts
let wsOpned = false

function ws_send (msg) {
  if (ws && (ws.readyState === 1)) {
    console.debug('WS-RQ', msg)
    ws.send(msg)
  } else if (wsOpned) {
    stash.push(msg)
  }
}

function ws_connect () {
  wsOpned = true
  if (!ws) {
    init()
  }
}

function ws_disconnect () {
  wsOpned = false
  if (ws) {
    ws.close()
    ws = null
  }
}

function ws_rpc (method, params, id) {
  const data = { jsonrpc: '2.0', method }
  if (params) {
    data.params = params
  }
  if (id) {
    data.id = id
  } else {
    data.id = method
  }
  ws_send(JSON.stringify(data))
}

function init () {
  if (ws) {
    ws.close()
  }

  ws = new WebSocket(wsUrl)

  ws.onopen = function () {
    if (wsTimer) {
      clearInterval(wsTimer)
      wsTimer = null
    }

    for (let i in events.onopen) {
      events.onopen[i]()
    }

    while (stash.length) {
      if (!ws) return
      ws_send(stash.shift())
    }
  }

  ws.onclose = function (e) {
    if (!wsTimer && wsOpts.reconnectionDelay && wsOpned) {
      wsTimer = setInterval(init, wsOpts.reconnectionDelay)
    }

    for (let i in events.onclose) {
      events.onclose[i](e.code, e.reason)
    }
  }

  ws.onmessage = function (e) {
    const eventData = e.data
    console.debug('WS-RS', eventData)
    const { error, result, id } = JSON.parse(eventData)
    // console.log('onmessage-2', error, result, id)

    if (error) {
      for (let i in events.rpc_error) {
        events.rpc_error[i](error.message, error.code)
      }
    }

    if (id) {
      for (let i in events.rpc_result) {
        events.rpc_result[i](id, result, error)
      }
    }

    if (result && result.message) {
      for (let i in events.onmessage) {
        events.onmessage[i](result.message)
      }
    }

    const af = rpc[id]
    if (af) {
      for (let i in af) {
        af[i](result, error)
      }
    }
  }
}

export default {
  install (Vue, url, opts = {}) {
    wsUrl = url
    wsOpts = opts

    Vue.config.optionMergeStrategies.ws = function (toVal, fromVal) {
      if (!toVal) return fromVal
      if (!fromVal) return toVal
      return Object.assign({}, toVal, fromVal)
    }

    const addListener = function () {
      const conf = this.$options.ws
      if (conf) {
        for (let i in conf) {
          const f = conf[i].bind(this)
          if (i in events) {
            events[i][this._uid] = f
          } else {
            if (!rpc[i]) {
              rpc[i] = {}
            }
            rpc[i][this._uid] = f
          }
        }
      }

      if (!(ws || wsOpts.connectManually)) {
        wsOpned = true
        init()
      }
    }

    const delListener = function () {
      for (let i in events) {
        delete events[i][this._uid]
      }
      for (let i in rpc) {
        delete rpc[i][this._uid]
      }
    }

    Vue.prototype.$ws_send = ws_send
    Vue.prototype.$ws_rpc = ws_rpc
    Vue.prototype.$ws_connect = ws_connect
    Vue.prototype.$ws_disconnect = ws_disconnect

    Vue.mixin({
      beforeCreate: addListener,
      beforeDestroy: delListener
    })
  }
}
